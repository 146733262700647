import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const SchemaGen = () => {
	const [schemaTemplate, setSchemaTemplate] = useState(
		"Schema Markup Template"
	);
	const [type, setType] = useState("");
	const [name, setName] = useState("");
	const [imgUrl, setImgUrl] = useState("");
	const [id, setId] = useState("");
	const [url, setUrl] = useState("");
	const [phone, setPhone] = useState("");
	const [priceRange, setPriceRange] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [monday, setMonday] = useState(false);
	const [tuesday, setTuesday] = useState(false);
	const [wednesday, setWednesday] = useState(false);
	const [thursday, setThursday] = useState(false);
	const [friday, setFriday] = useState(false);
	const [saturday, setSaturday] = useState(false);
	const [sunday, setSunday] = useState(false);

	const [country, setCountry] = useState("");
	const [province, setProvince] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [opens, setOpens] = useState("");
	const [closes, setCloses] = useState("");
	const [opensMonday, setOpensMonday] = useState("");
	const [closesMonday, setClosesMonday] = useState("");
	const [opensTuesday, setOpensTuesday] = useState("");
	const [closesTuesday, setClosesTuesday] = useState("");
	const [opensWednesday, setOpensWednesday] = useState("");
	const [closesWednesday, setClosesWednesday] = useState("");
	const [opensThursday, setOpensThursday] = useState("");
	const [closesThursday, setClosesThursday] = useState("");
	const [opensFriday, setOpensFriday] = useState("");
	const [closesFriday, setClosesFriday] = useState("");
	const [opensSaturday, setOpensSaturday] = useState("");
	const [closesSaturday, setClosesSaturday] = useState("");
	const [opensSunday, setOpensSunday] = useState("");
	const [closesSunday, setClosesSunday] = useState("");
	const [description, setDescription] = useState("");
	const [legalName, setLegalName] = useState("");
	const [email, setEmail] = useState("");
	const [currenciesAccepted, setCurrenciesAccepted] = useState("");
	const [numberOfEmployees, setNumberOfEmployees] = useState("");
	const [locationName, setLocationName] = useState("");
	const [locationType, setLocationType] = useState("");
	const [sameAsLocation, setSameAsLocation] = useState("");
	const [foundingLocationName, setFoundingLocationName] = useState("");
	const [foundingLocationType, setFoundingLocationType] = useState("");
	const [sameAsFoundingLocation, setSameAsFoundingLocation] = useState("");
	const [slogan, setSlogan] = useState("");
	const [mapUrl, setMapUrl] = useState("");
	const [founderType, setFounderType] = useState("");
	const [founderName, setFounderName] = useState("");
	const [founderId, setFounderId] = useState("");
	const [founderUrl, setFounderUrl] = useState("");
	const [founderDescription, setFounderDescription] = useState("");
	const [bestRating, setBestRating] = useState("");
	const [worstRating, setWorstRating] = useState("");
	const [ratingValue, setRatingValue] = useState("");
	const [reviewCount, setReviewCount] = useState("");
	const [reviewUrl, setReviewUrl] = useState("");
	const [contactId, setContactId] = useState("");
	const [contactUrl, setContactUrl] = useState("");
	const [contactImage, setContactImage] = useState("");
	const [contactName, setContactName] = useState("");
	const [contactDescription, setContactDescription] = useState("");
	const [contactTelephone, setContactTelephone] = useState("");
	const [contactType, setContactType] = useState("");
	const [socialLogo, setSocialLogo] = useState("");
	const [socialImage, setSocialImage] = useState("");

	const [idProduct, setIdProduct] = useState("");
	const [imgUrlProduct, setImgUrlProduct] = useState("");
	const [descriptionProduct, setDescriptionProduct] = useState("");
	const [logoProduct, setlogoProduct] = useState("");
	const [nameProduct, setnameProduct] = useState("");
	const [urlProduct, seturlProduct] = useState("");
	const [reviewCountProduct, setReviewCountProduct] = useState("");
	const [reviewBodyProduct, setReviewBodyProduct] = useState("");
	const [ratingValueProduct, setRatingValueProduct] = useState("");
	const [bestRatingProduct, setBestRatingProduct] = useState("");
	const [worstRatingProduct, setWorstRatingProduct] = useState("");
	const [urlReviewProduct, setUrlReviewProduct] = useState("");
	const [authorProduct, setAuthorProduct] = useState("");
	const [reviewRatingValueProduct, setReviewRatingValueProduct] = useState("");
	const [datePublishedProduct, setDatePublishedProduct] = useState("");

	const [areaList, setAreaList] = useState([
		{ type: "", name: "", sameAs: "" },
	]);
	const [faqList, setFaqList] = useState([{ question: "", answer: "" }]);
	const [breadcrumbList, setBreadcrumbList] = useState([{ name: "", url: "" }]);
	const [contactAreaList, setContactAreaList] = useState([
		{ type: "", name: "", sameAs: "" },
	]);
	const [socialList, setSocialList] = useState([{ url: "" }]);
	var daysOfTheWeek = [];

	const handleFaqChange = (e, index) => {
		const { name, value } = e.target;

		const list = [...faqList];
		list[index][name] = value;
		setFaqList(list);
	};

	const handleFaqRemove = (index) => {
		const list = [...faqList];
		list.splice(index, 1);
		setFaqList(list);
	};

	const handleFaqAdd = () => {
		setFaqList([...faqList, { question: "", answer: "" }]);
	};
	const handleBreadcrumbChange = (e, index) => {
		const { name, value } = e.target;

		const list = [...breadcrumbList];
		list[index][name] = value;
		setBreadcrumbList(list);
	};

	const handleBreadcrumbRemove = (index) => {
		const list = [...breadcrumbList];
		list.splice(index, 1);
		setBreadcrumbList(list);
	};

	const handleBreadcrumbAdd = () => {
		setBreadcrumbList([...breadcrumbList, { name: "", url: "" }]);
	};

	const handleServiceChange = (e, index) => {
		const { name, value } = e.target;

		const list = [...areaList];
		list[index][name] = value;
		setAreaList(list);
	};

	const handleServiceRemove = (index) => {
		const list = [...areaList];
		list.splice(index, 1);
		setAreaList(list);
	};
	const CopyToClipboard = (id) => {
		var r = document.createRange();
		r.selectNode(document.getElementById(id));
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(r);
		document.execCommand("copy");
		window.getSelection().removeAllRanges();
	};

	const handleServiceAdd = () => {
		setAreaList([...areaList, { type: "", name: "", sameAs: "" }]);
	};

	const handleContactServiceChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...contactAreaList];
		list[index][name] = value;
		setContactAreaList(list);
	};

	const handleContactServiceRemove = (index) => {
		const list = [...contactAreaList];
		list.splice(index, 1);
		setContactAreaList(list);
	};

	const handleContactServiceAdd = () => {
		setContactAreaList([
			...contactAreaList,
			{ type: "", name: "", sameAs: "" },
		]);
	};
	const handleSocialServiceChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...socialList];
		list[index][name] = value;
		setSocialList(list);
	};

	const handleSocialServiceRemove = (index) => {
		const list = [...socialList];
		list.splice(index, 1);
		setSocialList(list);
	};

	const handleSocialServiceAdd = () => {
		setSocialList([...socialList, { url: "" }]);
	};

	return (
		<Row className="h-100">
			<Col
				style={{ minHeight: "100%" }}
				className="  bg-blue-bg px-0 mx-0 py-5 py-lg-7"
				lg={3}
				xl={2}
			>
				<div
					style={{ overflow: "hidden", minHeight: "100%" }}
					className="bg-blue-bg position-relative schema-div "
				>
					<h1 className="fs-5 text-center text-light-blue">SCHEMA GENERATOR</h1>
					<Button
						variant={`${
							schemaTemplate === "Local Business" ? "secondary" : "dark-blue "
						}`}
						style={{ boarderRadius: "0px" }}
						className="w-100 fs-5 py-3 border-radius-0 no-active"
						onClick={() => setSchemaTemplate("Local Business")}
					>
						Local Business
					</Button>
					<Button
						onClick={() => setSchemaTemplate("FAQ Page")}
						variant={`${
							schemaTemplate === "FAQ Page" ? "secondary" : "dark-blue "
						}`}
						className={`w-100 fs-5 py-3 border-radius-0 no-active`}
					>
						FAQ Page
					</Button>
					<Button
						onClick={() => setSchemaTemplate("Breadcrumb")}
						variant={`${
							schemaTemplate === "Breadcrumb" ? "secondary" : "dark-blue "
						}`}
						className={`w-100 fs-5 py-3 border-radius-0 no-active`}
					>
						Breadcrumb
					</Button>
					<Button
						onClick={() => setSchemaTemplate("Product Schema")}
						variant={`${
							schemaTemplate === "Product Schema" ? "secondary" : "dark-blue "
						}`}
						className={`w-100 fs-5 py-3 border-radius-0 no-active`}
					>
						Product Schema
					</Button>
				</div>
			</Col>
			{schemaTemplate === "Product Schema" && (
				<Col lg={9}>
					<Row className="px-3 px-md-4 py-5 py-lg-7">
						<Col lg={7}>
							<Form>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://schema.org/Product"
								>
									(https://schema.org/Product)
								</a>
								<Row className="pb-3 g-3 mt-4">
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="landing page url"
											value={idProduct}
											onChange={(e) => setIdProduct(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="url to the main image on your landing page"
											value={imgUrlProduct}
											onChange={(e) => setImgUrlProduct(e.currentTarget.value)}
										/>
									</Col>

									<Col className="" xs={12}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											as="textarea"
											rows={3}
											placeholder="description of your locksmith business and what it does"
											value={descriptionProduct}
											onChange={(e) =>
												setDescriptionProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="image of your logo url"
											value={logoProduct}
											onChange={(e) => setlogoProduct(e.currentTarget.value)}
										/>
									</Col>

									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="name of your locksmith business"
											value={nameProduct}
											onChange={(e) => setnameProduct(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="your websites url"
											value={urlProduct}
											onChange={(e) => seturlProduct(e.currentTarget.value)}
										/>
									</Col>
								</Row>

								<Row className="g-3">
									<Col xs={12}>
										<Form.Label htmlFor="service">AggregateRating</Form.Label>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="no. of reviews you have"
											value={reviewCountProduct}
											onChange={(e) =>
												setReviewCountProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="average review rating e.g. 5"
											value={ratingValueProduct}
											onChange={(e) =>
												setRatingValueProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="best review rating (5)"
											value={bestRatingProduct}
											onChange={(e) =>
												setBestRatingProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="worst review rating (1)"
											value={worstRatingProduct}
											onChange={(e) =>
												setWorstRatingProduct(e.currentTarget.value)
											}
										/>
									</Col>
								</Row>
								<Row className="g-3">
									<Col xs={12}>
										<h2 className="mt-5">Your Most Recent Review </h2>
									</Col>
									<Col xs={12}>
										<Form.Label htmlFor="service">Review</Form.Label>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="your dedicated reviews landing page"
											value={urlReviewProduct}
											onChange={(e) =>
												setUrlReviewProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="date and time published"
											value={datePublishedProduct}
											onChange={(e) =>
												setDatePublishedProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col className="" xs={12}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											as="textarea"
											rows={3}
											placeholder="the most recent review you received."
											value={reviewBodyProduct}
											onChange={(e) =>
												setReviewBodyProduct(e.currentTarget.value)
											}
										/>
									</Col>
									<Col xs={12}>
										<Form.Label htmlFor="service">author</Form.Label>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="name of person who posted the review"
											value={authorProduct}
											onChange={(e) => setAuthorProduct(e.currentTarget.value)}
										/>
									</Col>
									<Col xs={12}>
										<Form.Label htmlFor="service">Review Rating</Form.Label>
									</Col>

									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="what rating the most recent review gave you e.g. 4"
											value={reviewRatingValueProduct}
											onChange={(e) =>
												setReviewRatingValueProduct(e.currentTarget.value)
											}
										/>
									</Col>
								</Row>
							</Form>
						</Col>
						<Col lg={5} xl={4}>
							<div className=" ms-lg-5 w-100 text-center">
								<Button
									type="button"
									onClick={() => CopyToClipboard("lsText")}
									className=" w-100 w-md-auto  white-link-yellow px-5 mt-3 mb-4  py-2"
								>
									<span>Copy Schema</span>
								</Button>
							</div>
							<div className=" mt-5 mt-lg-0 bg-schema-bg ms-lg-5 w-100 p-4">
								<p id="lsText" style={{ fontSize: "80%" }}>
									{`<script type="application/ld+json">`} <br />
									{`{`}
									<br />
									{`"@context": "https://schema.org",`}
									<br />
									{`"@type": "${"Product"}",`}
									<br />
									{`"@id": "${idProduct}",`}
									<br />
									{`"image": "${imgUrlProduct}",`}
									<br />
									{`"mpn": "${idProduct}",`}
									<br />
									{`"sku": "${idProduct}",`}
									<br />
									{`"description": "${descriptionProduct}",`}
									<br />
									{`"logo": "${logoProduct}",`}
									<br />
									{`"name": "${nameProduct}",`}
									<br />
									{`"url": "${urlProduct}",`}
									<br />
									{`"brand": {`}
									<br />
									{`"@type": "Organization",`}
									<br />
									{`"logo": "${logoProduct}",`}
									<br />
									{`"image": "${imgUrlProduct}",`}
									<br />
									{`"name": "${nameProduct}",`}
									<br />
									{`"url": "${urlProduct}"`}
									<br />
									{`},`}
									<br />
									{`"aggregateRating": {`}
									<br />
									{`"@type": "AggregateRating",`}
									<br />
									{`"reviewCount": "${reviewCountProduct}",`}
									<br />
									{`"ratingValue": "${ratingValueProduct}",`}
									<br />
									{`"bestRating": "${bestRatingProduct}",`}
									<br />
									{`"worstRating": "${worstRatingProduct}"`}
									<br />
									{`},`}
									<br />
									{`"review": {`}
									<br />
									{`"@type": "Review",`}
									<br />
									{`"url": "${urlReviewProduct}",`}
									<br />
									{`"datePublished": "${datePublishedProduct}",`}
									<br />
									{`"reviewBody": "${reviewBodyProduct}",`}
									<br />
									{`"author": {`}
									<br />
									{`"@type": "Person",`}
									<br />
									{`"name": "${authorProduct}"`}
									<br />
									{`},`}
									<br />
									{`"reviewRating": {`}
									<br />
									{`"@type": "Rating",`}
									<br />
									{`"ratingValue": "${reviewRatingValueProduct}",`}
									<br />
									{`"bestRating": "${bestRatingProduct}",`}
									<br />
									{`"worstRating": "${worstRatingProduct}"`}
									<br />
									{`},`}
									<br />
									{`"publisher": {`}
									<br />
									{`"@type": "Organization",`}
									<br />
									{`"name": "${nameProduct}",`}
									<br />
									{`"sameAs": "${urlProduct}"`}
									<br />
									{`}`}
									<br />
									{`}`}
									{`}`}
									<br />
									{`</script>`}
								</p>
							</div>
						</Col>
					</Row>
				</Col>
			)}

			{schemaTemplate === "Breadcrumb" && (
				<Col lg={9} xl={10}>
					<Row className="px-3 px-md-4 py-5  py-lg-7">
						<Col lg={6}>
							<Form>
								<div className="form-field">
									{breadcrumbList.map((singleArea, index) => (
										<div key={index} className="areas">
											<div className="first-division">
												<Form.Label htmlFor="name">Page Name</Form.Label>
												<Form.Control
													className="mb-3 w-100 py-3"
													name="name"
													type="text"
													id="name"
													placeholder="Type your page name here"
													value={singleArea.name}
													onChange={(e) => handleBreadcrumbChange(e, index)}
													required
												/>
												<Form.Label htmlFor="answer">Url</Form.Label>
												<Form.Control
													className="w-100 py-3"
													name="url"
													type="text"
													id="url"
													placeholder="Type your page url here"
													value={singleArea.url}
													onChange={(e) => handleBreadcrumbChange(e, index)}
													required
												/>
												{breadcrumbList.length - 1 === index && (
													<Button
														variant="primary"
														type="button"
														onClick={handleBreadcrumbAdd}
														className="add-btn  btn western mt-4 w-100 w-md-auto  white-link-yellow px-5   py-2 "
													>
														<span>Add Breadcrumb</span>
													</Button>
												)}
											</div>
											<div className=" my-3 second-division">
												{breadcrumbList.length !== 1 && (
													<Button
														variant="primary"
														type="button"
														onClick={() => handleBreadcrumbRemove(index)}
														className="remove-btn btn western w-100 w-md-auto  white-link-yellow px-5 mt-4 mt-md-0  py-2 "
													>
														<span>Remove</span>
													</Button>
												)}
											</div>
										</div>
									))}
								</div>
							</Form>
						</Col>
						<Col xl={4} lg={6}>
							<div className=" ms-lg-3 w-100 text-center">
								<Button
									type="button"
									onClick={() => CopyToClipboard("breadText")}
									className=" w-100 w-md-auto  white-link-yellow px-5 mt-3 mb-4  py-2"
								>
									<span>Copy Schema</span>
								</Button>
							</div>
							<div className=" mt-5 mt-lg-0 bg-schema-bg ms-lg-4 p-4">
								<p id="breadText" style={{ fontSize: "80%" }}>
									{`<script type="application/ld+json">`} <br />
									{`{`}
									<br />
									{`"@context": "https://schema.org",`}
									<br />
									{`"@type": "BreadcrumbList",`}
									<br />
									{`"itemListElement": [`}
									{breadcrumbList &&
										breadcrumbList.map((singleArea, index) => (
											<>
												<span key={index}>
													{`{`}
													<br />
													{`"@type": "ListItem",`}
													<br />
													{`"position": "${index + 1}",`}
													<br />
													{`"name": "${singleArea.name}",`}
													<br />
													{`"item": {`}
													<br />
													{`"url": "${singleArea.url}",`}
													<br />
													{`"id": "${singleArea.url}"`}
													<br />
													{`}`}
													<br /> {`}`}{" "}
													{index !== breadcrumbList.length - 1 && <>{`,`}</>}
												</span>
											</>
										))}
									{`]`}
									<br />
									{`}`}
									<br />
									{`</script>`}
								</p>
							</div>
						</Col>
					</Row>
				</Col>
			)}

			{schemaTemplate === "FAQ Page" && (
				<Col lg={9} xl={10}>
					<Row className="px-3 px-md-4 py-5  py-lg-7">
						<Col lg={6}>
							<Form>
								<div className="form-field">
									{faqList.map((singleArea, index) => (
										<div key={index} className="areas">
											<div className="first-division">
												<Form.Label htmlFor="question">Question</Form.Label>
												<Form.Control
													className="mb-3 w-100 py-3"
													name="question"
													type="text"
													id="question"
													placeholder="Type your question here"
													value={singleArea.question}
													onChange={(e) => handleFaqChange(e, index)}
													required
												/>
												<Form.Label htmlFor="answer">Answer</Form.Label>
												<Form.Control
													as="textarea"
													className="w-100 py-3"
													name="answer"
													rows={5}
													type="text"
													id="answer"
													placeholder="Type your answer here"
													value={singleArea.answer}
													onChange={(e) => handleFaqChange(e, index)}
													required
												/>
												{faqList.length - 1 === index && (
													<Button
														variant="primary"
														type="button"
														onClick={handleFaqAdd}
														className="add-btn  btn western mt-4 w-100 w-md-auto  white-link-yellow px-5   py-2 "
													>
														<span>Add Question</span>
													</Button>
												)}
											</div>
											<div className=" my-3 second-division">
												{faqList.length !== 1 && (
													<Button
														variant="primary"
														type="button"
														onClick={() => handleFaqRemove(index)}
														className="remove-btn btn western w-100 w-md-auto  white-link-yellow px-5 mt-4 mt-md-0  py-2 "
													>
														<span>Remove</span>
													</Button>
												)}
											</div>
										</div>
									))}
								</div>
							</Form>
						</Col>
						<Col xl={4} lg={6}>
							<div className=" ms-lg-3 w-100 text-center">
								<Button
									type="button"
									onClick={() => CopyToClipboard("faqText")}
									className=" w-100 w-md-auto  white-link-yellow px-5 mt-3 mb-4  py-2"
								>
									<span>Copy Schema</span>
								</Button>
							</div>
							<div className=" mt-5 mt-lg-0 bg-schema-bg ms-lg-4 p-4">
								<p id="faqText" style={{ fontSize: "80%" }}>
									{`<script type="application/ld+json">`} <br />
									{`{`}
									<br />
									{`"@context": "https://schema.org",`}
									<br />
									{`"@type": "FAQPage",`}
									<br />
									{`"mainEntity": [`}
									{faqList &&
										faqList.map((singleArea, index) => (
											<>
												<span key={index}>
													{`{`}
													<br />
													{`"@type": "Question",`}
													<br />
													{`"name": "${singleArea.question}",`}
													<br />
													{`"acceptedAnswer": {`}
													<br />
													{`"@type": "Answer",`}
													<br />
													{`"text": "${singleArea.answer}"`}
													<br />
													{`}`}
													<br /> {`}`}{" "}
													{index !== faqList.length - 1 && <>{`,`}</>}
												</span>
											</>
										))}
									{`]`}
									<br />
									{`}`}
									<br />
									{`</script>`}
								</p>
							</div>
						</Col>
					</Row>
				</Col>
			)}

			{schemaTemplate === "Local Business" && (
				<Col lg={9}>
					<Row className="px-3 px-md-4 py-5 py-lg-7">
						<Col lg={7}>
							<Form>
								<Form.Control
									disabled
									className="mb-3 w-50 py-3"
									type="text"
									placeholder="@type"
									value={type}
									onChange={(e) => setType(e.currentTarget.value)}
								/>{" "}
								<Row className="pb-3">
									<Col lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Company Name"
											value={name}
											onChange={(e) => setName(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Legal name"
											value={legalName}
											onChange={(e) => setLegalName(e.currentTarget.value)}
										/>
									</Col>

									<Col className="my-3" xs={12}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											as="textarea"
											rows={3}
											placeholder="description"
											value={description}
											onChange={(e) => setDescription(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="URL of image of company"
											value={imgUrl}
											onChange={(e) => setImgUrl(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											type="text"
											className="w-100 py-3"
											placeholder="URL of company"
											value={id}
											onChange={(e) => setId(e.currentTarget.value)}
										/>
									</Col>
									<Col>
										{" "}
										<Form.Control
											as="textarea"
											rows={3}
											className="w-100 mt-3"
											placeholder="Company slogan"
											value={slogan}
											onChange={(e) => setSlogan(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row className="pb-3"></Row>
								<Row className="pb-3">
									<Col lg={4}>
										<Form.Control
											className="py-3"
											type="text"
											placeholder="Company website address"
											value={url}
											onChange={(e) => setUrl(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={4}>
										{" "}
										<Form.Control
											className="py-3"
											type="text"
											placeholder="Company main switchboard telephone"
											value={phone}
											onChange={(e) => setPhone(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={4}>
										{" "}
										<Form.Control
											className="py-3"
											type="text"
											placeholder="Price range"
											value={priceRange}
											onChange={(e) => setPriceRange(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row className="py-3">
									<Col>
										{" "}
										<div className="form-field">
											<Form.Label htmlFor="service">Area(s) Served</Form.Label>
											{areaList.map((singleArea, index) => (
												<div key={index} className="areas">
													<div className="first-division">
														<Form.Select
															name="type"
															className="mb-3 w-100 py-3"
															type="text"
															id="type"
															placeholder="type (city)"
															onChange={(e) => handleServiceChange(e, index)}
															value={singleArea.type}
															required
														>
															{/* <option value="type">Type</option> */}
															<option value="city">City</option>
														</Form.Select>
														<Form.Control
															className="mb-3 w-100 py-3"
															name="name"
															type="text"
															id="areaName"
															placeholder="Name of Area"
															value={singleArea.name}
															onChange={(e) => handleServiceChange(e, index)}
															required
														/>
														<Form.Control
															className="w-100 py-3"
															name="sameAs"
															type="text"
															id="sameAs"
															placeholder="Same As Example:(https://en.wikipedia.org/wiki/London)"
															value={singleArea.sameAs}
															onChange={(e) => handleServiceChange(e, index)}
															required
														/>
														{areaList.length - 1 === index && (
															<Button
																type="button"
																onClick={handleServiceAdd}
																className="add-btn mt-3 w-100 w-md-auto  white-link-yellow px-5   py-2"
															>
																<span>Add An Area</span>
															</Button>
														)}
													</div>
													<div className=" my-3 second-division">
														{areaList.length !== 1 && (
															<Button
																type="button"
																onClick={() => handleServiceRemove(index)}
																className="remove-btn w-100 w-md-auto  white-link-yellow px-5 mt-3  py-2"
															>
																<span>Remove</span>
															</Button>
														)}
													</div>
												</div>
											))}
										</div>
									</Col>
								</Row>
								<Row className="pb-3">
									<Col xs={12}>
										<Form.Label>More info:</Form.Label>
									</Col>
									<Col lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="email"
											placeholder="Email"
											value={email}
											onChange={(e) => setEmail(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Number of employees"
											value={numberOfEmployees}
											onChange={(e) =>
												setNumberOfEmployees(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											className="w-100 mt-3 py-3"
											type="text"
											placeholder="Currencies Accepted (GBP)"
											value={currenciesAccepted}
											onChange={(e) =>
												setCurrenciesAccepted(e.currentTarget.value)
											}
										/>
									</Col>
								</Row>
								<Row className="pb-3">
									<Col xs={12}>
										<Form.Label>Head Office Location</Form.Label>
									</Col>
									<Col lg={6}>
										<Form.Select
											name="city"
											className="mb-3 w-100 py-3"
											type="text"
											id="type"
											placeholder="type (city)"
											onChange={(e) => setLocationType(e.currentTarget.value)}
											required
										>
											{/* <option value="type">Type</option> */}
											<option value="city">City</option>
										</Form.Select>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Name of city"
											value={locationName}
											onChange={(e) => setLocationName(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={12}>
										{" "}
										<Form.Control
											className="w-100 mt-3 py-3"
											type="text"
											placeholder="Same As Example:(https://en.wikipedia.org/wiki/london)"
											value={sameAsLocation}
											onChange={(e) => setSameAsLocation(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row className="pb-3">
									<Col xs={12}>
										<Form.Label>Founding Location</Form.Label>
									</Col>
									<Col lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Type (city)"
											value={foundingLocationType}
											onChange={(e) =>
												setFoundingLocationType(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Name of city"
											value={foundingLocationName}
											onChange={(e) =>
												setFoundingLocationName(e.currentTarget.value)
											}
										/>
									</Col>
									<Col lg={12}>
										{" "}
										<Form.Control
											className="w-100 mt-3 py-3"
											type="text"
											placeholder="Same As Example:(https://en.wikipedia.org/wiki/london)"
											value={sameAsFoundingLocation}
											onChange={(e) =>
												setSameAsFoundingLocation(e.currentTarget.value)
											}
										/>
									</Col>
								</Row>
								<Row className="pb-3">
									<Col xs={12}>
										<Form.Label>Address</Form.Label>
									</Col>
									<Col lg={4}>
										<Form.Control
											className="py-3"
											type="text"
											placeholder="Street"
											value={street}
											onChange={(e) => setStreet(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={4}>
										{" "}
										<Form.Control
											className="py-3"
											type="text"
											placeholder="City"
											value={city}
											onChange={(e) => setCity(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={4}>
										{" "}
										<Form.Control
											className="py-3"
											type="text"
											placeholder="Post code"
											value={zipCode}
											onChange={(e) => setZipCode(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row className="pb-3">
									<Col lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Country"
											value={country}
											onChange={(e) => setCountry(e.currentTarget.value)}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="County"
											value={province}
											onChange={(e) => setProvince(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<Form.Label>
											Geo coordinates:{" "}
											<a
												href="https://www.gps-coordinates.net/"
												target="_blank"
												rel="noreferrer"
												className="primary-link"
											>
												https://www.gps-coordinates.net/
											</a>
										</Form.Label>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Latitude"
											value={latitude}
											onChange={(e) => setLatitude(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										{" "}
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Longitude"
											value={longitude}
											onChange={(e) => setLongitude(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Google map URL"
											value={mapUrl}
											onChange={(e) => setMapUrl(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<Form.Label>Opening Hours:</Form.Label>
									</Col>
									<Col className="py-3" lg={12}>
										<Form.Label className="d-block">
											Day(s) of the week
										</Form.Label>
										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block  me-3"
													id="Monday"
													name="Monday"
													value={monday}
													onClick={(e) => setMonday(!monday)}
												></Form.Check>
												<Form.Label for="Monday">Monday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!monday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensMonday}
													onChange={(e) =>
														setOpensMonday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!monday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesMonday}
													onChange={(e) =>
														setClosesMonday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>

										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block me-3 "
													id="Tuesday"
													name="Tuesday"
													value={tuesday}
													onClick={(e) => setTuesday(!tuesday)}
												></Form.Check>
												<Form.Label for="Tuesday">Tuesday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!tuesday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensTuesday}
													onChange={(e) =>
														setOpensTuesday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!tuesday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesTuesday}
													onChange={(e) =>
														setClosesTuesday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>

										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block me-3 "
													id="Wednesday"
													name="Wednesday"
													value={wednesday}
													onClick={(e) => setWednesday(!wednesday)}
												></Form.Check>
												<Form.Label for="Wednesday">Wednesday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!wednesday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensWednesday}
													onChange={(e) =>
														setOpensWednesday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!wednesday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesWednesday}
													onChange={(e) =>
														setClosesWednesday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>

										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block me-3 "
													id="Thursday"
													name="Thursday"
													value={thursday}
													onClick={(e) => setThursday(!thursday)}
												></Form.Check>
												<Form.Label for="Thursday">Thursday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!thursday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensThursday}
													onChange={(e) =>
														setOpensThursday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!thursday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesThursday}
													onChange={(e) =>
														setClosesThursday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>

										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block me-3 "
													id="Friday"
													name="Friday"
													value={friday}
													onClick={(e) => setFriday(!friday)}
												></Form.Check>
												<Form.Label for="Friday">Friday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!friday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensFriday}
													onChange={(e) =>
														setOpensFriday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!friday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesFriday}
													onChange={(e) =>
														setClosesFriday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>

										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block me-3 "
													id="Saturday"
													name="Saturday"
													value={saturday}
													onClick={(e) => setSaturday(!saturday)}
												></Form.Check>
												<Form.Label for="Saturday">Saturday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!saturday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensSaturday}
													onChange={(e) =>
														setOpensSaturday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!saturday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesSaturday}
													onChange={(e) =>
														setClosesSaturday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>

										<Row className="align-items-center">
											<Col class lg={12}>
												<Form.Check
													className="d-inline-block me-3 "
													id="Sunday"
													name="Sunday"
													value={sunday}
													onClick={(e) => setSunday(!sunday)}
												></Form.Check>
												<Form.Label for="Sunday">Sunday</Form.Label>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!sunday}
													className="w-100 py-3"
													type="text"
													placeholder="Opens at (e.g. 08:00)"
													value={opensSunday}
													onChange={(e) =>
														setOpensSunday(e.currentTarget.value)
													}
												/>
											</Col>
											<Col className="pb-3" lg={6}>
												{" "}
												<Form.Control
													disabled={!sunday}
													className="w-100 py-3"
													type="text"
													placeholder="Closes at (e.g. 21:00)"
													value={closesSunday}
													onChange={(e) =>
														setClosesSunday(e.currentTarget.value)
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<Form.Label>Founder</Form.Label>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="type (Person)"
											value={founderType}
											onChange={(e) => setFounderType(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Name"
											value={founderName}
											onChange={(e) => setFounderName(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Id"
											value={founderId}
											onChange={(e) => setFounderId(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Url"
											value={founderUrl}
											onChange={(e) => setFounderUrl(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={12}>
										<Form.Control
											className="w-100"
											rows={3}
											as="textarea"
											placeholder="Description"
											value={founderDescription}
											onChange={(e) =>
												setFounderDescription(e.currentTarget.value)
											}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<Form.Label>Aggregate Rating</Form.Label>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Best rating"
											value={bestRating}
											onChange={(e) => setBestRating(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Worst rating"
											value={worstRating}
											onChange={(e) => setWorstRating(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Rating value"
											value={ratingValue}
											onChange={(e) => setRatingValue(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Review count"
											value={reviewCount}
											onChange={(e) => setReviewCount(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={12}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Review page url"
											value={reviewUrl}
											onChange={(e) => setReviewUrl(e.currentTarget.value)}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<Form.Label>Contact Point</Form.Label>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Id (url)"
											value={contactId}
											onChange={(e) => setContactId(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="url"
											value={contactUrl}
											onChange={(e) => setContactUrl(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="image (url)"
											value={contactImage}
											onChange={(e) => setContactImage(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Name"
											value={contactName}
											onChange={(e) => setContactName(e.currentTarget.value)}
										/>
									</Col>
									<Col className="pb-3" lg={12}>
										<Form.Control
											className="w-100"
											rows={3}
											as="textarea"
											placeholder="Description"
											value={contactDescription}
											onChange={(e) =>
												setContactDescription(e.currentTarget.value)
											}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Telephone"
											value={contactTelephone}
											onChange={(e) =>
												setContactTelephone(e.currentTarget.value)
											}
										/>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="Contact Type"
											value={contactType}
											onChange={(e) => setContactType(e.currentTarget.value)}
										/>
									</Col>
									<Col>
										{" "}
										<div className="form-field">
											<Form.Label htmlFor="service">Area(s) Served</Form.Label>
											{contactAreaList.map((singleArea, index) => (
												<div key={index} className="areas">
													<div className="first-division">
														<Form.Control
															className="mb-3 w-100 py-3"
															name="type"
															type="text"
															id="type"
															placeholder="type (city)"
															value={singleArea.type}
															onChange={(e) =>
																handleContactServiceChange(e, index)
															}
															required
														/>
														<Form.Control
															className="mb-3 w-100 py-3"
															name="name"
															type="text"
															id="areaName"
															placeholder="Name"
															value={singleArea.name}
															onChange={(e) =>
																handleContactServiceChange(e, index)
															}
															required
														/>
														<Form.Control
															className="w-100 py-3"
															name="sameAs"
															type="text"
															id="sameAs"
															placeholder="Same As Example:(https://en.wikipedia.org/wiki/Shoreham-by-Sea)"
															value={singleArea.sameAs}
															onChange={(e) =>
																handleContactServiceChange(e, index)
															}
															required
														/>
														{contactAreaList.length - 1 === index && (
															<Button
																type="button"
																onClick={handleContactServiceAdd}
																className="add-btn w-100 w-md-auto  white-link-yellow px-5 mt-3  py-2"
															>
																<span>Add An Area</span>
															</Button>
														)}
													</div>
													<div className=" my-3 second-division">
														{contactAreaList.length !== 1 && (
															<Button
																type="button"
																onClick={() =>
																	handleContactServiceRemove(index)
																}
																className="remove-btn w-100 w-md-auto  white-link-yellow px-5 mt-3  py-2"
															>
																<span>Remove</span>
															</Button>
														)}
													</div>
												</div>
											))}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<Form.Label>Social Info:</Form.Label>
									</Col>
									<Col className="pb-3" lg={6}>
										<Form.Control
											className="w-100 py-3"
											type="text"
											placeholder="logo (url)"
											value={socialLogo}
											onChange={(e) => setSocialLogo(e.currentTarget.value)}
										/>
									</Col>
									{/* <Col className="pb-3" lg={6}>
									<Form.Control
										className="w-100"
										type="text"
										placeholder="Image (url)"
										value={socialImage}
										onChange={(e) => setSocialImage(e.currentTarget.value)}
									/>
								</Col> */}
									<Col xs={12}>
										{" "}
										<div className="form-field">
											<Form.Label htmlFor="service">Social Links</Form.Label>
											{socialList.map((singleUrl, index) => (
												<div key={index} className="areas">
													<div className="first-division">
														<Form.Control
															className="mb-3 w-100 py-3"
															name="url"
															type="text"
															id="url"
															placeholder="Url"
															value={singleUrl.url}
															onChange={(e) =>
																handleSocialServiceChange(e, index)
															}
															required
														/>

														{socialList.length - 1 === index && (
															<Button
																type="button"
																onClick={handleSocialServiceAdd}
																className="add-btn w-100 w-md-auto  white-link-yellow px-5 mt-3  py-2"
															>
																<span>Add Url</span>
															</Button>
														)}
													</div>
													<div className=" my-3 second-division">
														{socialList.length !== 1 && (
															<Button
																type="button"
																onClick={() => handleSocialServiceRemove(index)}
																className="remove-btn w-100 w-md-auto  white-link-yellow px-5 mt-3  py-2"
															>
																<span>Remove</span>
															</Button>
														)}
													</div>
												</div>
											))}
										</div>
									</Col>
								</Row>
							</Form>
						</Col>
						<Col lg={5} xl={4}>
							<div className=" ms-lg-5 w-100 text-center">
								<Button
									type="button"
									onClick={() => CopyToClipboard("lsText")}
									className=" w-100 w-md-auto  white-link-yellow px-5 mt-3 mb-4  py-2"
								>
									<span>Copy Schema</span>
								</Button>
							</div>
							<div className=" mt-5 mt-lg-0 bg-schema-bg ms-lg-5 w-100 p-4">
								<p id="lsText" style={{ fontSize: "80%" }}>
									{`<script type="application/ld+json">`} <br />
									{`{`}
									<br />
									{`"@context": "https://schema.org",`}
									<br />
									{`"@type": "${type || "LocalBusiness"}",`}
									<br />
									{`"name": "${name}",`}
									<br />
									{`"legalName": "${legalName}",`}
									<br />
									{`"description": "${description}",`}
									<br />
									{`"image": "${imgUrl}",`}
									<br />
									{`"@id": "https://${id}",`}
									<br />
									{`"slogan": "${slogan}",`}
									<br />
									{`"url": "${url}",`}
									<br />
									{`"telephone": "${phone}",`}
									<br />
									{`"priceRange": "${priceRange}",`}
									<br />
									{`"areaServed": [`}
									<br />
									{areaList &&
										areaList.map((singleArea, index) => (
											<>
												<span key={index}>
													{`{`}
													<br />
													{`"@type": "${singleArea.type || "City"}",`}
													<br />
													{`"name": "${singleArea.name}",`}
													<br />
													{`"sameAs": "${singleArea.sameAs}"`}
													<br />
													{`}`} {index !== areaList.length - 1 && <>{`,`}</>}
													<br />
												</span>
											</>
										))}
									{`],`}
									<br />
									{`"email": "${email}",`}
									<br />
									{`"currenciesAccepted": "${currenciesAccepted}",`}
									<br />
									{`"numberOfEmployees": "${numberOfEmployees}",`}
									<br />
									{`"location": {`}
									<br />
									{`"@type": "${locationType || "City"}",`}
									<br />
									{`"name": "${locationName}",`}
									<br />
									{`"sameAs": "${sameAsLocation}"`}
									<br />
									{`},`}
									<br />
									{`"foundingLocation": {`}
									<br />
									{`"@type": "${foundingLocationType}",`}
									<br />
									{`"name": "${foundingLocationName}",`}
									<br />
									{`"sameAs": "${sameAsFoundingLocation}"`}
									<br />
									{`},`}
									<br />
									{`"address": {`}
									<br />
									{`"@type": "PostalAddress",`}
									<br />
									{`"streetAddress": "${street}",`}
									<br />
									{`"addressLocality": "${city}",`}
									<br />
									{`"addressRegion": "${province}",`}
									<br />
									{`"postalCode": "${zipCode}",`}
									<br />
									{`"addressCountry": "${country}"`}
									<br />
									{`},`}
									<br />
									{`"geo": {`}
									<br />
									{`"@type": "GeoCoordinates",`}
									<br />
									{`"latitude": ${latitude},`}
									<br />
									{`"longitude": ${longitude}`}
									<br />
									{`},`}
									<br />
									{`"hasMap": "${mapUrl}",`}
									<br />
									{`"openingHoursSpecification": [`}
									<br />
									{monday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesMonday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Monday",`}
											<br />
											{`"opens": "${opensMonday}"`}
											<br />
											{`}`}
											{sunday ||
											saturday ||
											friday ||
											thursday ||
											wednesday ||
											tuesday
												? ","
												: ""}
										</span>
									)}
									{monday && <br />}
									{tuesday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesTuesday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Tuesday",`}
											<br />
											{`"opens": "${opensTuesday}"`}
											<br />
											{`}`}
											{sunday || saturday || friday || thursday || wednesday
												? ","
												: ""}
										</span>
									)}
									{tuesday && <br />}
									{wednesday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesWednesday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Wednesday",`}
											<br />
											{`"opens": "${opensWednesday}"`}
											<br />
											{`}`}
											{sunday || saturday || friday || thursday ? "," : ""}
										</span>
									)}
									{wednesday && <br />}
									{thursday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesThursday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Thursday",`}
											<br />
											{`"opens": "${opensThursday}"`}
											<br />
											{`}`}
											{sunday || saturday || friday ? "," : ""}
										</span>
									)}
									{thursday && <br />}
									{friday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesFriday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Friday",`}
											<br />
											{`"opens": "${opensFriday}"`}
											<br />
											{`}`}
											{sunday || saturday ? "," : ""}
										</span>
									)}
									{friday && <br />}
									{saturday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesSaturday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Saturday",`}
											<br />
											{`"opens": "${opensSaturday}"`}
											<br />
											{`}`}
											{sunday ? "," : ""}
										</span>
									)}
									{saturday && <br />}
									{sunday && (
										<span>
											{`{`}
											<br />
											{` "@type": "OpeningHoursSpecification",`}
											<br />
											{`"closes":  "${closesSunday}",`}
											<br />
											{`"dayOfWeek": "https://schema.org/Sunday",`}
											<br />
											{`"opens": "${opensSunday}"`}
											<br />
											{`}`}
										</span>
									)}
									{sunday && <br />}
									{`],`}
									<br />
									{`"founder": {`}
									<br />
									{`"@type": "${founderType || "Person"}",`}
									<br />
									{`"name": "${founderName}",`}
									<br />
									{`"id": "${founderId}",`}
									<br />
									{`"url": "${founderUrl}",`}
									<br />
									{`"description": "${founderDescription}"`}
									<br />
									{`},`}
									<br />
									{`"aggregateRating": {`}
									<br />
									{`"@type": "AggregateRating",`}
									<br />
									{`"bestRating": "${bestRating || "5"}",`}
									<br />
									{`"worstRating": "${worstRating || "0"}",`}
									<br />
									{`"ratingValue": "${ratingValue || "4.9"}",`}
									<br />
									{`"reviewCount": "${reviewCount || "20"}",`}
									<br />
									{`"url": "${reviewUrl}"`}
									<br />
									{`},`}
									<br />
									{`"contactPoint": {`}
									<br />
									{`"@type": "ContactPoint",`}
									<br />
									{`"@id": "${contactId}",`}
									<br />
									{`"url": "${contactUrl}",`}
									<br />
									{`"image": "${contactImage}",`}
									<br />
									{`"name": "${contactName}",`}
									<br />
									{`"description": "${contactDescription}",`}
									<br />
									{`"telephone": "${contactTelephone}",`}
									<br />
									{`"contactType": "${contactType}",`}
									<br />
									{`"availableLanguage": "en",`}
									<br />
									{`"areaServed": [`}
									<br />
									{contactAreaList &&
										contactAreaList.map((singleArea, index) => (
											<>
												<span key={index}>
													{`{`}
													<br />
													{`"@type": "${singleArea.type}",`}
													<br />
													{`"name": "${singleArea.name}",`}
													<br />
													{`"sameAs": "${singleArea.sameAs}"`}

													<br />
													{`}`}
													{index !== contactAreaList.length - 1 && <>{`,`}</>}
													<br />
												</span>
											</>
										))}
									{`]`}
									<br />
									{`},`}
									<br />
									{`"logo": "${socialLogo}",`}
									<br />
									{/* {`"image": "${socialImage}",`}
							<br /> */}
									{`"sameAs": [`}
									<br />
									{socialList &&
										socialList.map((singleUrl, index) => (
											<>
												<span key={index}>
													{`"${singleUrl.url}"`}
													{index !== socialList.length - 1 && <>{`,`}</>}
													<br />
												</span>
											</>
										))}
									{`]`}
									<br />
									{`},`}
									<br />
									{`</script>`}
								</p>
							</div>
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
};

export default SchemaGen;
